import React from 'react';
import Card from 'react-bootstrap/Card';
import "./styles/boardMembers.css";
import { withRouter } from "react-router-dom";
import ContentWrapper from '../contentWrapper/ContentWrapper'

const members = [
    {
        name: "Helen Beteet",
        title: "President",
        photo:"helen_beteet.JPG"
    },
    {
        name: "Thomas Gordan",
        title: "Vice President"
    },
    {
        name: "Hazel Davis",
        title: "Treasurer",
        photo: "hazel-davis.jpg"
    },
    {
        name: "Glenda Arrington",
        title: "Financial Secretary"
    },
    {
        name: "Tanesha Thompson",
        title: "Secretary",
        photo: "tanesha-thompson.jpeg"
    },
    {
        name: "Clarence Brown",
        title: "Sergeant at Arms",
        photo: "clarence_brown_pic.jpg"
    },
    {
        name: "Tai Barber-Gumbs",
        title: "Board Member",
        photo: "Tai_Barber_Gumbs-Pic.jpg"
    },
    {
        name: "Betty Ewell",
        title: "Board Member"
    },
    {
        name: "Cedric Patton",
        title: "Board Member"
    },
    {
        name: "Brenda Price",
        title: "Board Member"
    }
];

class BoardMembers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    Navigate = (to) => {
        this.props.history.push(to);
    }
    getCard = (member,index) => {
        const imagesBase = process.env.PUBLIC_URL + '/images/';
        const noPhotosImage = `${imagesBase}/${member.photo || "NoPersonPhoto.png"}`;
        return (
            <li className="board-members__member" key={ index}>
                <Card style={{ width: '12rem', height:'300px' }}>
                    <div  style={{height: '198px',backgroundImage: `url(${noPhotosImage})`,backgroundSize: 'cover',backgroundRepeat: 'noRepeat',backgroundPositionX: 'center',backgroundPositionY: 'center'}}>
                    </div>
                    <Card.Body style={{height:'107px'}}>
                        <Card.Title>{member.name}</Card.Title>
                        <Card.Text>{member.title}</Card.Text>
                    </Card.Body>
                </Card>
            </li>
        );
    }
    render() {
        return (
            <ContentWrapper header="Board Members">
                <ul className="board-members">
                    {members.map((member,i) => this.getCard(member,i))}
                </ul>
            </ContentWrapper>
        );
    }
}

export default withRouter(BoardMembers);