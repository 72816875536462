import React from 'react';
import "./styles/donate.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';
import Image from 'react-bootstrap/Image';


function Donate() {
    return (
        <ContentWrapper header="Donate">
            <div className="donate">
                <ol>
                        <h3>Scan to Donate</h3>
                        <Image className='donate-scan' src={process.env.PUBLIC_URL + "/Vernon_Windows_QRE_Code.png"} />
                        <p></p>
                        
                        <h3>Donate Online</h3>
                        <p><a href='https://buy.stripe.com/dR628k8f89BLeIg3ce'>Click here to donate</a></p>
                        <h3>Mail-in Donation</h3>
                        <p>
                            We also accept checks/ money orders etc. sent to our Post Office Box: 4268 Kansas City, KS 66104
                        </p>
                </ol>
            </div>
        </ContentWrapper>
    );
}

export default Donate;