import React from 'react';

//import Image from 'react-bootstrap/Image';
import "./styles/bid.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function Bid() {
    return (
        <ContentWrapper header="Request For Bid">
            <div className="bid">
                <p>
                Vernon Multipurpose Center, Incorporated (hereinafter referred to as “VMPCI”) is seeking
                bids for architectural services and construction project management services for the
                Vernon School Rehabilitation project. If your firm is interested in submitting a response,
                please do so in accordance with the instructions contained within this Request for Bid.
                Responses are due no later than 5:00pm CST/CDT, February 28, 2025.
                </p>
                <p>
                The Request for Bid document can be found: <a href="./bid_request.pdf">here</a>.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default Bid;