import React from 'react';
import Banner from '../../banner/Banner'
import ShowcaseLinks from '../showcaseLinks/ShowcaseLinks';
import './styles/home.css'

function Home() {
    return (
        <>
            <Banner />
            <div className="mission-overlay">
                <div>
                    Our Mission is to function as an outreach facility, serving citizens in the
                    northeast area of Wyandotte County, Kansas City Kansas. The Center
                    attempts to enhance the general physical well being of the community it
                    serves.
                </div>
            </div>
            <div className="announcements">
                <div>
                    Vernon Fall Gala - October 11, 2025 - Save the Date!
                </div>
            </div>
            <ShowcaseLinks />
        </>
    );
}

export default Home;