import React from 'react';
import "./styles/about.css";
import ContentWrapper from '../contentWrapper/ContentWrapper';


function About() {
    return (
        <ContentWrapper header="About">
            <div className="about">
                <h3>Vernon School & Quindaro Railroad Museum</h3>
                <p>
                        The Vernon School, originally known as the Colored School of Quindaro, opened in 1858 
                        to educate African American children in the abolitionist community of Quindaro, Kansas. It 
                        provided education to many African Americans, including former slaves who had recently arrived 
                        in the territory. The school was segregated, run by its own district (#17) with an all-African American 
                        board. By the 1930s, the school was overcrowded, leading to the construction of a new brick building in 1936, 
                        funded by President Roosevelt’s New Deal Program. The new school had four rooms and served grades 1-8, with two 
                        additional rooms added in the 1950's.
                </p>
                <p>
                        The school was renamed Vernon School after William Tecumseh Vernon, an influential African American leader, educator, 
                        and advocate for civil rights. At just 25 years old, Vernon had already made significant contributions to education and 
                        African American advancement. He had served as president of Western University and was appointed Register of the US Treasury by 
                        President Theodore Roosevelt in 1906. He also became a bishop in the African Methodist Episcopal Church and worked as a missionary 
                        in South Africa.
                </p>
                <p>
                        After the landmark 1954 Brown vs. Board of Education decision, which desegregated schools, a third of Vernon School’s students were transferred 
                        to integrate Quindaro Elementary in 1955. Due to desegregation, construction on a highway that divided the community,  and a declining 
                        population in Quindaro, the school closed in 1971. The building was repurposed as a community center and remains so today. In 2004, it was 
                        added to the Kansas Historic Register and is now called the Vernon Multipurpose Center.  In 2023, the Vernon School was added to the National 
                        Register of Historic Places. The center serves Wyandotte County residents, promoting physical well-being and community outreach, while also 
                        housing the Underground Railroad Museum, which helps preserve historical documents.
                </p>
            </div>
        </ContentWrapper>
    );
}

export default About;